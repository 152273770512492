<template>
  <div class="ds-wrapper relative z-10">
    <vs-card class="ds-card mb-4 md:mb-6">
      <div slot="header" class="flex flex-row justify-between">
        <h4>Additional funding requests</h4>
      </div>
      <div>
        <div class="pt-5">
          <div v-for="(data, index) in topUps" :key="index">
            <div class="grid grid-cols-2 sm:grid-cols-3 gap-y-6 gap-x-20" :ref="data._id">
              <div>
                <div class="flex flex-col gap-y-2">
                  <p class="text-ancillary text-body-dark">Date requested</p>
                  <p class="text-primary">{{ data.createdAt }}</p>
                </div>
              </div>
              <div>
                <div class="flex flex-col gap-y-2">
                  <p class="text-ancillary text-body-dark">Last updated</p>
                  <p class="text-primary">{{ data.updatedAt }}</p>
                  <p class="text-primary" v-if="data.status == 'Submitted'">{{ data.createdByName }}</p>
                  <p class="text-primary" v-else>{{ getActionedByName(data) }}</p>
                </div>
              </div>
              <div>
                <div class="flex">
                  <div class="flex flex-col gap-y-2">
                    <p class="text-ancillary text-body-dark">Status</p>
                    <p>
                      <vs-chip :color="changeStatusColor(data.status)" size="small">{{ data.status }}</vs-chip>
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div class="flex flex-col gap-y-2">
                  <p class="text-ancillary text-body-dark">{{ data.status == "Approved" ? "Funded" : "Amount" }} <span v-if="displayServiceFee(data)">/ service fee</span></p>
                  <p class="text-primary">{{ formatCurrency(data.amount) }} <span v-if="displayServiceFee(data)">/ {{ formatCurrency(data.fee) }}</span></p>
                </div>
              </div>
              <div v-if="data.notes">
                <div class="flex flex-col gap-y-2">
                  <p class="text-ancillary text-body-dark">Notes</p>
                  <p class="text-primary">{{ data.notes }}</p>
                </div>
              </div>
              <div class="sm:col-span-3" v-if="data.attachments && data.attachments.length">
                <div class="flex flex-col gap-y-2">
                  <p class="text-ancillary text-body-dark">Supporting docs</p>
                  <div class="flex flex-col">
                     <template v-for="(attachment, key) in data.attachments">
                      <S3FileGenerator :document="attachment" :key="key" />
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-8" v-if="data.status == 'Paid' && data.linkedPaymentRequestId">
              <p class="text-primary mb-2">Payment details</p>
              <div class="flex flex-row flex-wrap gap-y-6 gap-x-12">
                <div>
                  <div class="flex flex-col gap-y-2">
                    <p class="text-ancillary text-body-dark">TX reference</p>
                    <p class="text-primary">{{ data.linkedPaymentRequestId.paymentRequestId }}</p>
                  </div>
                </div>
                <div>
                  <div class="flex flex-col gap-y-1">
                    <p class="text-ancillary text-body-dark">Method</p>
                    <div class="flex flex-row items-center pointer" v-if="data.linkedPaymentRequestId.customerPaymentMethods[0].type == 'CARD'">
                      <img :src="getCardType(data.linkedPaymentRequestId.customerPaymentMethods[0].card_type)" :alt="data.linkedPaymentRequestId.customerPaymentMethods[0].card_type" />
                      <p class="text-primary">
                        <span class="ml-4 md:ml-6">**** {{ data.linkedPaymentRequestId.customerPaymentMethods[0].last_four_digits }}</span><span class="ml-4 md:ml-6">{{ getExpiredYear(data.linkedPaymentRequestId.customerPaymentMethods[0]) }}</span>
                      </p>
                    </div>
                    <div class="flex flex-row items-center pointer" v-if="data.linkedPaymentRequestId.customerPaymentMethods[0].type == 'EFT'">
                      <p class="text-primary">EFT/PayID</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="flex flex-col gap-y-2">
                    <p class="text-ancillary text-body-dark">Amount paid</p>
                    <p class="text-primary">
                      {{ formatCurrency(data.linkedPaymentRequestId.totalAmount || data.linkedPaymentRequestId.amount.$numberDecimal) }}
                      <span v-if="data.linkedPaymentRequestId.feeText">({{ data.linkedPaymentRequestId.feeText }})</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-8" v-else>
              <div class="flex flex-col gap-y-2">
                <p class="text-ancillary text-body-dark" v-if="data.status == 'Pending'">Your action required</p>
                <p v-html="getActionText(data)"></p>
                <p>
                  <a :href="getProductTerms(data)" v-if="data.status == 'Approved' && data.productTerms" target="_blank">Terms & Conditions</a>
                </p>
              </div>
              <!-----using v-show instead of v-if to fix clientWidth/scrollHeight issue with vuesax component while rendering-->
              <div class="flex flex-row gap-x-4 mt-4 flex-wrap gap-y-2" v-if="displayTopupAction(data)">
                <div v-show="displayAddToAccount(data) && !((showTopUpAgreement || showTopUpPaymentOptions) && topupId == data._id)">
                  <vs-button
                    flat
                    v-round
                    size="medium"
                    :disabled="data.acceptanceType && data.acceptanceType == 'PayLater'"
                    @click="handleTopUpAction(data, 'later')"
                  >
                    <span>Add to this agreement</span>
                  </vs-button>
                </div>
                <div v-show="displayPayNow(data) && !((showTopUpAgreement || showTopUpPaymentOptions) && topupId == data._id)">
                  <vs-button flat v-round size="medium" @click="handleTopUpAction(data, 'now')">
                    <span>Pay now</span>
                  </vs-button>
                </div>
              </div>

              <!-------------Pay Now topup card------------->
              <div v-if="showTopUpPaymentOptions && (topupId == data._id)" class="md:px-2 pt-4">
                <div class="px-4">
                  <p class="mb-2 sm:mb-3">Select a method to make your payment</p>
                  <div class="flex flex-col border-b-list">
                    <vs-alert :active="'true'" color="warning" icon="error_outline" class="mb-2 md:mb-4 single-line-alert" v-if="displayCardWarning">
                      <p>Please add card to your account to make payment</p>
                    </vs-alert>
                    <template v-if="partnerPayNowOptions.includes('CARDS') && cardOptions.length > 0">
                      <div class="flex flex-row justify-between align-center py-4" v-for="(paymentMethod, index) in cardOptions" :key="index">
                        <div class="flex flex-row items-center" v-if="paymentMethod.type == 'CARD'">
                          <vs-radio v-model="selectedPaymentOption" vs-name="selectedPayNowPaymentOption" :id="index" :vs-value="paymentMethod" />
                          <label :for="index" class="flex flex-row items-center pointer">
                            <img :src="getCardType(paymentMethod.card_type)" :alt="paymentMethod.card_type" />
                            <p class="ml-4 md:ml-6">
                              <span>**** {{ paymentMethod.last_four_digits }}</span>
                              <span class="ml-4 md:ml-6">{{ getExpiredYear(paymentMethod) }}</span>
                              <span class="ml-4 md:ml-6">{{ getCardFees(data, paymentMethod) }}</span>
                            </p>
                          </label>
                        </div>
                      </div>
                    </template>

                    <div class="flex flex-row justify-between align-center py-4" v-if="partnerPayNowOptions.includes('EFT') && showEft">
                      <div class="flex flex-row items-center">
                        <vs-radio v-model="selectedPaymentOption" vs-name="selectedPayNowPaymentOption" :vs-value="'EFT'" />
                        <label class="flex flex-row items-center pointer">
                          <img :src="payId" alt="EFT" width="50px" />
                          <p class="ml-4 md:ml-6">
                            <span>Bank transfer (EFT)</span>
                            <span class="ml-4 md:ml-6">Free & secure via PayId (recommended), or EFT via BSB/account</span>
                          </p>
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr class="mb-6" />
                  <eft
                    v-if="selectedPaymentOption === 'EFT' && data.linkedPaymentRequestId"
                    :prWithPartnerDetail="data.linkedPaymentRequestId"
                    :selectedAmount="selectedAmount"
                    :reference-style="'center'"
                    :description="eftDescription"
                    @closePayment="cancelTopUpAction()"
                  />
                  <div v-else-if="selectedPaymentOption === 'EFT'">
                    <div class="flex flex-col gap-y-8">
                      <div>
                        <div class="text-center mb-6 md:mb-5">
                          <p class="text-sm">Amount to transfer</p>
                          <h2>{{ formatCurrency(selectedAmount || 0) }}</h2>
                        </div>
                        <div class="text-center mb-6 md:mb-5">
                          <vs-button flat v-round size="medium" @click="generateEFTReference">
                            <span>Generate unique reference</span>
                          </vs-button>
                        </div>

                        <vs-divider class="my-8 max-w-xs mx-auto" />

                        <p class="text-body text-center mb-2">Tap the button to generate a unique reference for your payment, ensure you note it when sending funds to avoid delays in processing.
                          We recommend using the PayID shown - it's free, secure and fast.</p>

                        <div class="text-center">
                          <a class="btn-text" @click="cancelTopUpAction()">Cancel</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <template v-if="selectedPaymentOption != 'EFT'">
                  <vs-button color="primary" class="block w-full max-w-400 mx-auto mb-4" @click="makePayment" :disabled="!proceedPayment">{{ buttonText }}</vs-button>
                  <vs-button type="flat" class="block mx-auto mb-2 md:mb-0" @click="cancelTopUpAction()">Cancel</vs-button>
                </template>
              </div>

              <!----------Add to this agreement card--------->
              <div v-if="showTopUpAgreement && (topupId == data._id)" class="md:px-2 pt-4 flex flex-col items-center">
                <h3 class="text-primary my-4">Your updated agreement</h3>

                <div class="flex justify-between items-center mb-2 w-full max-w-420">
                  <p class="text-primary">Current agreement</p>
                </div>
                <div class="flex justify-between items-center mb-2 w-full max-w-420">
                  <p>{{ product && product.summaryAmountLabel ? product.summaryAmountLabel : "Purchase amount" }}</p>
                  <p class="text-sm">$ {{ addZeroes(agreementAmount) }}</p>
                </div>
                <div class="flex justify-between items-center mb-4 w-full max-w-420">
                  <p>{{ product && product.summaryFeeLabel ? product.summaryFeeLabel : "Service fee" }}</p>
                  <p class="text-sm">$ {{ addZeroes(agreementFees) }}</p>
                </div>
                <div class="flex justify-between items-center mb-2 w-full max-w-420">
                  <p class="text-primary">Additional funding request</p>
                </div>
                <div class="flex justify-between items-center mb-2 w-full max-w-420">
                  <p>Amount</p>
                  <p class="text-sm">$ {{ addZeroes(data.amount) }}</p>
                </div>
                <div class="flex justify-between items-center mb-4 w-full max-w-420">
                  <p>Service fee</p>
                  <p class="text-sm">$ {{ addZeroes(data.fee) }}</p>
                </div>
                <div class="flex justify-between items-center mb-2 w-full max-w-420">
                  <p class="text-primary">Total new agreement</p>
                  <p class="text-primary">$ {{ addZeroes(newAgreementAmount(data)) }}</p>
                </div>
                <div v-if="getSummaryMessage" class="mt-5">
                  <p>{{ getSummaryMessage }}</p>
                </div>
                <div class="flex flex-row items-start mb-4 mt-6 max-w-420 custom-quillEditor legals-custom payment-legals">
                  <vs-checkbox v-model="tnc_acceptance" name="tnc_acceptance"> </vs-checkbox>
                  <p class="ancillary-regular cursor-pointer terms-view-content ql-editor text-justify" v-html="productLegals.explicitTerms"></p>
                </div>
                <span class="text-danger text-sm my-2" v-show="errors.has('tnc_acceptance')">{{ errors.first("tnc_acceptance") }}</span>
                <template>
                  <vs-button color="primary" size="large" class="block w-full max-w-400 mx-auto mb-4" @click="addToAccount" :disabled="!tnc_acceptance">Add to this agreement</vs-button>
                  <vs-button type="flat" @click="cancelTopUpAction()" size="medium" class="block mx-auto mb-2 md:mb-0">Cancel</vs-button>
                </template>
              </div>
            </div>
            <hr class="my-8 line-hr" v-if="showLine(index)" />
          </div>
        </div>
      </div>
    </vs-card>
  </div>
</template>

<script>
  import S3FileGenerator from "@/views/customer/components/S3FileGenerator";
  import CardPayIcon from "../../components/icons/CardPayIcon.vue";
  import BankTransferIcon from "../../components/icons/BankTransferIcon";
  import Eft from "../components/eft.vue";

  import { mapActions, mapGetters } from "vuex";
  import moment from "moment";

  // Credit Cards
  const visaCard = require("@/assets/images/cards/visa.svg");
  const masterCard = require("@/assets/images/cards/mastercard.svg");
  const amexCard = require("@/assets/images/cards/amex.svg");
  const dinersClubCard = require("@/assets/images/cards/dinersclub.svg");
  const unionpayCard = require("@/assets/images/cards/unionpay.svg");
  const payId = require("@/assets/images/cards/payid.svg");

  export default {
    props: ["legals", "paymentRequestDetail", "product", "scrollToTopupId", "topUps", "transactions"],

    components: { BankTransferIcon, CardPayIcon, Eft, S3FileGenerator },

    data() {
      return {
        appName: process.env.VUE_APP_NAME == "RelloPay" ? "Rello" : "Flip",
        appUrl: process.env.VUE_APP_API_URL,
        merchantName: "",
        productLegals: "",
        tnc_acceptance: false,
        topupId: "",
        tabIndex: "",
        visaCard: visaCard,
        masterCard: masterCard,
        amexCard: amexCard,
        dinersClubCard: dinersClubCard,
        unionpayCard: unionpayCard,
        payId: payId,
        displayCardWarning: false,
        partnerPayNowOptions: [],
        showTopUpPaymentOptions: false,
        showTopUpAgreement: false,
        selectedPaymentOption: {},
        selectedAmount: 0,
        eftDescription: "",
      }
    },

    methods: {
      ...mapActions("paymentRequest", ["updateTopUp"]),
      ...mapGetters("customer", ["getCustomerPaymentMethods"]),

      changeStatusColor(status) {
        let bgClass = "";

        switch (status) {
          case "Approved":
          case "Paid":
            bgClass = "success";
            break;
          case "Cancelled":
            bgClass = "secondary-500";
            break;
          case "Declined":
            bgClass = "error";
            break;
          case "Submitted":
            bgClass = "primary";
            break;
          default:
            bgClass = "primary";
            break;
        }
        return bgClass;
      },

      getCardType(cardType) {
        let card = "";

        switch (cardType) {
          case "visa":
            card = this.visaCard;
            break;

          case "mastercard":
            card = this.masterCard;
            break;

          case "amexCard":
            card = this.amexCard;
            break;

          case "unionpay":
            card = this.unionunionpayCardpay;
            break;

          default:
            card = this.visaCard;
            break;
        }
        return card;
      },

      getExpiredYear(date) {
        if (!date) {
          return null;
        }
        return `Exp: ${date.month}/${date.year.substring(2, 4)}`;
      },

      showLine(index) {
        return index < (this.topUps.length - 1);
      },

      getActionedByName(data) {
        let name = data.actionedByName || null;

        if ((data.actionedByRefModel == "Admin") || ["Approved", "Declined"].includes(data.status)) {
          name = process.env.VUE_APP_NAME.toLowerCase().includes("flip") ? "FlipPay" : "Rello";
        }

        if (name) {
          name = `By ${name}`;
        }

        return name;
      },

      handleTopUpAction(data, topupType) {
        this.topupId = data._id;

        if (topupType == "later") {
          this.showTopUpAgreement = true;
          this.showTopUpPaymentOptions = false;
        } else {
          this.showTopUpPaymentOptions = true;
          this.showTopUpAgreement = false;

          if (this.cardOptions.length) {
            const selectCard = this.cardOptions.find((item) => item.default == true);
            this.selectedPaymentOption = selectCard || this.cardOptions[0];
          } else if (this.showEft) {
            this.selectedPaymentOption = "EFT";
          }

          this.selectedAmount = data.amount;
        }
      },

      cancelTopUpAction() {
        this.showTopUpAgreement = false;
        this.showTopUpPaymentOptions = false;
        this.topupId = "";
        this.selectedPaymentOption = {};
        this.selectedAmount = 0;
      },

      setLegals() {
        const privacyLegals = this.legals.privacy;
        const termsAndCondition = this.legals.websiteTerms;
        this.productLegals = this.product.legals;

        if (this.productLegals.explicitTerms) {
          this.productLegals.explicitTerms = this.replaceLegalContent(
            this.productLegals.explicitTerms,
            {
              fullTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.productLegals._id}?type=${this.productLegals.nature}&fullDocument=true" target="_blank">${this.productLegals.documentName}</a>`,
              privacy: `<a href="${this.appUrl}pages/terms-and-conditions/${privacyLegals._id}?type=DASHBOARD&fullDocument=true" target="_blank">${privacyLegals.documentName}</a>`,
              generalWebsiteTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${termsAndCondition._id}?type=DASHBOARD&fullDocument=true" target="_blank">${termsAndCondition.documentName}</a>`,
            }
          );
        }
      },

      async addToAccount() {
        if (!this.tnc_acceptance) {
          this.errors.add({
            "field": "tnc_acceptance",
            "msg": "Please accept terms and conditions to continue"
          });

          return false;
        }

        this.$vs.loading();

        const payload = {
          acceptanceType: "PayLater",
          productTerms: this.productLegals._id,
          topUpId: this.topupId
        };

        await this.processTopup(payload);
      },

      displayTopupAction(data) {
        return ["Pending", "Declined"].includes(data.status); // To allow PayNow payment for declined status as well
      },

      displayPayNow(data) {
        return !(data.acceptanceType && data.acceptanceType == "PayLater") || ["Declined"].includes(data.status);
      },

      displayAddToAccount(data) {
        return !(data.acceptanceType && ["PayNow"].includes(data.acceptanceType)) && ["Pending"].includes(data.status);
      },

      async makePayment() {
        if (!this.proceedPayment) {
          this.$vs.notify({
            title: "Error",
            text: "Please select at least one option",
            color: "danger",
          });

          return;
        }

        this.$vs.loading();

        const payload = {
          acceptanceType: "PayNow",
          topUpId: this.topupId,
          paymentMethod: { ...this.selectedPaymentOption }
        };

        await this.processTopup(payload);
      },

      async generateEFTReference() {
        this.$vs.loading();

        const payload = {
          acceptanceType: "EFT",
          topUpId: this.topupId
        };

        await this.processTopup(payload, false);
      },

      async processTopup(payload, reloadContent = true) {
        await this.updateTopUp(payload).then((result) => {
          this.$vs.loading.close();
          this.showToastMessage("Top-up", result.data.message, (result.data.data && result.data.data.showWarning) ? "error" : "success");

          if (reloadContent) {
            this.$emit("reloadContent", this.topupId);
          }

          // DISPLAY EFT DETAILS WITHOUT PAGE REFRESH INCASE OF EFT
          if (!reloadContent && result.data.data.linkedPaymentRequestId) {
            const topupIndex = this.topUps.findIndex((item) => item._id == this.topupId);

            if (topupIndex >= 0) {
              this.topUps[topupIndex].acceptanceType = payload.acceptanceType;
              this.topUps[topupIndex].linkedPaymentRequestId = {
                ...result.data.data.linkedPaymentRequestId,
                partner: this.paymentRequestDetail.partner
              }
            }

            this.$forceUpdate();
          }
        }).catch((ex) => {
          this.$vs.loading.close();
          this.showToastMessage("Top-up", ex.data.message, "error");
        });
      },

      getActionText(data) {
        let text = "";

        switch (data.status) {
          case "Approved":
            text = `<p class='para-def-light-anc mb-2'>You requested additional funds to be added to this agreement, which was approved by ${this.appName} on ${data.adminActionedDate}.</p>
              <p class='para-def-light-anc mb-2'>The requested funds have been disbursed to ${this.merchantName}, and will be repaid per the terms of the initial agreement.</p>`;
            break;
          case "Declined":
            text = `<p class='para-def-light-anc mb-2'>You requested to add the additional amount to this agreement, which was declined by ${this.appName} at ${data.adminActionedDate}.</p>
              <p class='para-def-light-anc mb-2'>You can pay ${this.merchantName} directly via card/EFT/PayID, or advise ${this.merchantName} to cancel this additional request.</p>`;
            break;
          case "Pending":
            text = `<p class='para-def-light-anc mb-2'>You can add the additional request amount to this agreement (subject to ${this.appName} approval) or you can pay it today via card/EFT/PayId
            to ${this.merchantName}</p><p class='para-def-light-anc mb-2'>If neither option is acceptable, please advise ${this.merchantName} to cancel this additional request.</p>`;
            break;
          case "Submitted":
            text = `<p class='para-def-light-anc mb-2'>You've requested to add the additional amount to this agreement, subject to ${this.appName} approval.</p>
              <p class='para-def-light-anc mb-2'>We'll accept or decline your request for increased funding within the next 2 business days. You'll receive email confirmation of our decision,
              no further action should be required from you (unless we need some additional information). </p>
              <p class='para-def-light-anc mb-2'>Approved requests will be disbursed to ${this.merchantName}, and repaid per the terms of the initial agreement.</p>`;
            break;
        }

        return text;
      },

      newAgreementAmount(topup) {
        return this.currencyAdd(this.currencyAdd(this.agreementAmount, this.agreementFees), this.currencyAdd(topup.amount, topup.fee));
      },

      formatScheduleDate(date) {
        return moment(date).format("DD MMM YYYY");
      },

      addZeroes(num) {
        num = String(num);
        return Number(num).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        });
      },

      getCardFees(topup, paymentMethod) {
        const paymentCard = this.paymentRequestDetail.paymentCards.find((item) => item.card.slug == paymentMethod.card_type);

        if (paymentCard) {
          const fee = ((topup.amount / 100) * paymentCard.surcharge).toFixed(2);

          return `${paymentCard.surcharge}% card surcharge added ($${this.addZeroes(fee)} for this payment)`;
        }
      },

      displayServiceFee(topup) {
        return ["Approved"].includes(topup.status) && (parseFloat(topup.fee) > 0);
      },

      getProductTerms(topup) {
        if (topup.productTerms) {
          return `${this.appUrl}pages/terms-and-conditions/${topup.productTerms}?type=PAY_LATER_LEGAL&fullDocument=true`;
        }
      },

      scrollToTopup(topupId) {
        const el = this.$refs[topupId];

        if (el && el[0]) {
          el[0].scrollIntoView({ behavior: "smooth", block: "start" });
        }
      },
    },

    async mounted() {
      this.setLegals();
      this.partnerPayNowOptions = this.paymentRequestDetail.partner[0].payNowOptions;
      this.merchantName = this.paymentRequestDetail.partner[0].companyName;
      this.eftDescription = `Ensure you use the transaction reference above when sending funds to ${this.appName}, otherwise your payment may be delayed.
      You'll get a confirmation email and/or text message once your payment is processed. This can take up to 3 business days.`;

      if (this.scrollToTopupId) {
        this.scrollToTopup(this.scrollToTopupId);
      }
    },

    watch: {
      tnc_acceptance(val) {
        if (val && this.errors.has("tnc_acceptance")) {
          this.errors.remove("tnc_acceptance");
        }
      }
    },

    computed: {
      proceedPayment() {
        return Object.keys(this.selectedPaymentOption).length > 0;
      },

      showEft() {
        const paymentMethods = (this.product && this.product.paymentMethods && this.product.paymentMethods.length) ? this.product.paymentMethods : [];

        return paymentMethods.includes("EFT");
      },

      buttonText() {
        return "Pay " + this.formatCurrency(this.payNowAmount || 0);
      },

      cardOptions() {
        let customerPaymentMethods = [ ...this.getCustomerPaymentMethods() ];
        customerPaymentMethods = customerPaymentMethods.filter(item => item.type == "CARD");

        this.displayCardWarning = (customerPaymentMethods.length == 0);

        return customerPaymentMethods;
      },

      agreementAmount() {
        const transactionAmount = this.transactions.find((item) => item.type == "Disbursement");
        let amount = transactionAmount.amount;

        if (this.topUps && this.topUps.length > 0) {
          const topupAmount = this.topUps.filter(item => ["Approved"].includes(item.status)).reduce((accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue.amount.toFixed(2));
          }, 0);

          amount = this.currencyAdd(amount, topupAmount);
        }

        return amount;
      },

      agreementFees() {
        const transactionFee = this.transactions.find((item) => item.type == "Fee" && item.subType == "Service Fee");
        let fee = transactionFee.amount;

        if (this.topUps && this.topUps.length > 0) {
          const topupFee = this.topUps.filter(item => ["Approved"].includes(item.status)).reduce((accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue.fee.toFixed(2));
          }, 0);

          fee = this.currencyAdd(fee, topupFee);
        }

        return fee;
      },

      getSummaryMessage() {
        if (this.product && ["B2C_BNPO", "B2C_BNPO_NC"].includes(this.product.productType)) {
          let message = this.product.summaryMessage || "Our share of proceeds will be deducted on";
          const schedule = this.transactions.filter(item => item.type == "Payment" && item.status == "Scheduled");

          return `${message} ${this.formatScheduleDate(schedule[schedule.length - 1].date)}`;
        }
      },

      payNowAmount() {
        let fee = 0;

        if (this.selectedPaymentOption && this.selectedPaymentOption.card_type) {
          const paymentCard = this.paymentRequestDetail.paymentCards.find((item) => item.card.slug == this.selectedPaymentOption.card_type);
          fee = paymentCard ? ((this.selectedAmount / 100) * paymentCard.surcharge).toFixed(2) : fee;
        }

        return this.currencyAdd(fee, this.selectedAmount);
      }
    }
  };
</script>

